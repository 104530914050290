@property --angle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

.moving-rainbow-gradient {
  margin: 0;
  font-family: "Exo", sans-serif;
  color: #fff;
  background-size: 100% 100%;
  background: conic-gradient(
      from var(--angle),
      rgba(55, 248, 2, 0.3),
      rgba(231, 60, 126, 0.3),
      rgba(255, 195, 40, 0.3),
      rgba(255, 39, 0, 0.3),
      rgba(70, 255, 1, 0.3)
    )
    center;
  -webkit-animation: gradientBG 8s linear infinite;
  animation: gradientBG 8s linear infinite;
}

@-webkit-keyframes gradientBG {
  0% {
    --angle: 0deg;
  }
  50% {
    --angle: 180deg;
  }
  100% {
    --angle: 360deg;
  }
}

@keyframes gradientBG {
  0% {
    --angle: 0deg;
  }
  50% {
    --angle: 180deg;
  }
  100% {
    --angle: 360deg;
  }
}
